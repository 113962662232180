














































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixin';
import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { ROUTE_LOGIN } from '@/router/routes';
import AuthRepository from '@/api/repositories/AuthRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import User from '@/models/User';

const authRepository: AuthRepository = RepositoryFactory.get('auth');

@Component({
    mixins: [validationMixin],
    validations: {
        password: {
            required,
            minLength: minLength(8)
        },
        passwordRepeat: {
            required,
            sameAs: sameAs('password'),
            minLength: minLength(8)
        }
    }
})
export default class LoginView extends mixins(ErrorMessageHandlerMixin) {

    public snackbarMsg: string = '';
    public showSnackbar: boolean = false;

    public password: string | null = null;
    public passwordRepeat: string | null = null;

    public isFormSubmitted: boolean = false;
    public isLoading: boolean = false;
    public isLocked: boolean = false;

    public manager?: User;

    public async mounted() {
        this.snackbarMsg = this.$t('INVITE.CODE_NOT_FOUND').toString();

        const inviteCode = this.$route.query.inviteCode as string;

        if (!inviteCode) {
            this.isLocked = true;
            this.showSnackbar = true;
        } else {
            try {
                this.manager = await authRepository.getByInviteCode({ inviteCode });
            } catch (e) {
                this.snackbarMsg = this.$t('INVITE.INVALID_CODE').toString();
                this.isLocked = true;
                this.showSnackbar = true;
            }
        }
    }

    public backToLogin() {
        this.$router.push({ name: ROUTE_LOGIN });
    }

    /**
     * Tries to login
     */
    public async onLoginFormSubmit() {
        this.isFormSubmitted = true;
        // Trigger validation
        this.$v.$touch();
        const inviteCode = this.$route.query.inviteCode as string;

        if (!this.$v.$invalid && !this.isLoading) {
            try {
                this.isLoading = true;
                await authRepository.setManagerPassword({
                    inviteCode,
                    password: this.password!
                });

                this.snackbarMsg = this.$t('INVITE.SAVED_PASSWORD').toString();
                this.showSnackbar = true;
                this.isLocked = true;

                setTimeout(() => { // Wait before going back to the login
                    this.backToLogin();
                }, 3000);
            } catch (e) {
                this.showSnackbar = true;
                this.snackbarMsg = this.$t('INVITE.PASSWORD_ERROR').toString();
            } finally {
                this.isLoading = false;
            }
        }
    }

}
